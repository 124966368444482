<template>
  <div class="signup-overview">
    <div
      :style="{ color: gmPrimaryColor }"
      class="sw-block-subtitle text-center"
    >
      {{ $vuetify.lang.t("$vuetify.confirmationHeadline") }}
    </div>
    <div class="sw-mb-5 sw-text text-center">
      {{ $vuetify.lang.t("$vuetify.editInformationText") }}
    </div>
    <v-divider :color="gmPrimaryColor" class="sw-my-5"></v-divider>
    <!-- profile -->
    <v-row no-gutters class="sw-text">
      <v-col cols="10">
        <v-row no-gutters>
          <v-col cols="7">
            {{ $vuetify.lang.t("$vuetify.name") }}
          </v-col>
          <v-col cols="5">
            {{ user.first_name }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="7">
            {{ $vuetify.lang.t("$vuetify.surname") }}
          </v-col>
          <v-col cols="5">
            {{ user.last_name }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="7">
            {{ $vuetify.lang.t("$vuetify.phone") }}
          </v-col>
          <v-col v-if="user.phone_code && user.phone" cols="5"
            >+{{ user.phone_code }} {{ user.phone }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="7">
            {{ $vuetify.lang.t("$vuetify.email") }}
          </v-col>
          <v-col cols="5">
            {{ user.email }}
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="2" class="text-right">
        <a :style="{ color: gmSecondaryColor }" @click.stop="editUser">
          {{ $vuetify.lang.t("$vuetify.edit") }}
        </a>
      </v-col>
    </v-row>
    <!-- eo profile -->
    <v-divider :color="gmPrimaryColor" class="sw-my-5"></v-divider>
    <!-- answers -->
    <div class="pb-5 mb-4 sw-text">
      {{ $vuetify.lang.t("$vuetify.yourAnswers") }}
    </div>
    <template v-if="!attributeTagsEnabled">
      <template v-for="(attribute, i) in transformedUserAttributes">
        <v-row
          v-if="!attribute.hidden"
          :key="`answer-${i}`"
          no-gutters
          class="sw-text mb-4"
        >
          <v-col cols="9">
            <h5>{{ attribute.name }}</h5>
          </v-col>
          <v-col cols="3" class="text-right">
            <a
              :style="{ color: gmSecondaryColor }"
              @click.stop="changeAnswer(attribute)"
              >{{ $vuetify.lang.t("$vuetify.edit") }}</a
            >
          </v-col>
          <v-col v-if="attribute.type === 'options'" cols="12">
            <p>
              <template v-for="(answer, index) in attribute.answer">
                <template>
                  <div :key="`answer-${index}`">
                    {{ answer.name }}
                    <template v-if="answer.price">
                      <template v-if="!attribute.is_ticket">
                        ({{ formatPrice(answer.price, groupCurrency) }})
                      </template>
                      <template v-if="attribute.is_ticket">
                        ({{
                          formatTicketPrice(
                            answer.price,
                            platformFee,
                            groupCurrency,
                          )
                        }})
                      </template>
                    </template>
                  </div>
                </template>
              </template>
            </p>
          </v-col>
          <v-col
            v-if="attribute.type === 'varchar'"
            cols="12"
            class="font-weight-light"
          >
            {{ attribute.answer }}
          </v-col>
          <v-col
            v-if="attribute.type === 'text'"
            cols="12"
            class="font-weight-light"
          >
            <template v-if="attribute.answer">
              <nl2br tag="p" :text="attribute.answer" />
            </template>
          </v-col>
          <v-col
            v-if="attribute.type === 'datetime'"
            cols="12"
            class="font-weight-light"
          >
            {{ dateFormattedDate(attribute.answer) }}
          </v-col>
        </v-row>
      </template>
    </template>
    <template v-if="attributeTagsEnabled">
      <v-row
        v-for="(tag, tagIndex) in groupedQuestions"
        :key="`tag-${tagIndex}`"
        class="no-gutters no-wrap pb-5"
      >
        <v-col cols="10">
          <div v-if="!tag.hide_name || !tag.hide_description" class="mb-4">
            <template v-if="!tag.hide_name">
              <h4>
                <strong>{{ tag.name }}</strong>
              </h4>
            </template>
            <template v-if="!tag.hide_description">
              <p>{{ tag.description }}</p>
            </template>
          </div>
          <template v-for="(attribute, i) in tag.attributes">
            <div
              v-if="!attribute.hidden"
              :key="`answer-${i}`"
              class="sw-text mb-4"
            >
              <h5>{{ attribute.name }}</h5>

              <template v-if="attribute.type === 'options'">
                <p>
                  <template v-for="(answer, index) in attribute.answer">
                    <template>
                      <div :key="`answer-${index}`">
                        {{ answer.name }}
                        <template v-if="answer.price">
                          <template v-if="!attribute.is_ticket">
                            ({{ formatPrice(answer.price, groupCurrency) }})
                          </template>
                          <template v-if="attribute.is_ticket">
                            ({{
                              formatTicketPrice(
                                answer.price,
                                platformFee,
                                groupCurrency,
                              )
                            }})
                          </template>
                        </template>
                      </div>
                    </template>
                  </template>
                </p>
              </template>

              <template v-if="attribute.type === 'text'">
                <template v-if="attribute.answer">
                  <nl2br tag="p" :text="attribute.answer" />
                </template>
              </template>

              <template v-if="attribute.type === 'varchar'">
                <p>{{ attribute.answer }}</p>
              </template>

              <div
                v-if="attribute.type === 'datetime'"
                class="font-weight-light"
              >
                <p>{{ dateFormattedDate(attribute.answer) }}</p>
              </div>
            </div>
          </template>
        </v-col>
        <v-col cols="2" class="align-self-center">
          <div class="text-right">
            <a
              :style="{ color: gmSecondaryColor }"
              @click.stop="changeAnswers(tag)"
              >{{ $vuetify.lang.t("$vuetify.edit") }}</a
            >
          </div>
        </v-col>
      </v-row>
    </template>
    <!-- eo answers -->
    <v-divider :color="gmPrimaryColor" class="sw-my-5"></v-divider>
    <!-- payment -->
    <template v-if="totalAmount > 0">
      <div class="font-weight-thin">
        {{ $vuetify.lang.t("$vuetify.payment") }}
      </div>
      <v-row no-gutters class="font-italic">
        <v-col cols="9"
          >{{ $vuetify.lang.t("$vuetify.totalExcludingVat") }} ({{
            defaultTaxRate
          }}%)</v-col
        >
        <v-col cols="3">{{
          formatPrice(totalAmountExcludingTax, groupCurrency)
        }}</v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="9"
          >{{ $vuetify.lang.t("$vuetify.totalIncludingVat") }} ({{
            defaultTaxRate
          }}%)</v-col
        >
        <v-col cols="3" class="font-weight-regular">{{
          formatPrice(totalAmount, groupCurrency)
        }}</v-col>
      </v-row>
      <v-divider :color="gmPrimaryColor" class="sw-my-5"></v-divider>
    </template>
    <!-- eo payment -->
    <div class="sw-pt-7 text-center">
      <v-btn
        rounded
        large
        depressed
        :style="{ backgroundColor: gmSecondaryColor }"
        class="
          text-none
          white--text
          sw-rounded
          sw-height-48px
        "
        :loading="isLoading"
        @click="next()"
      >
        <span class="sw-px-2">
          {{ $vuetify.lang.t("$vuetify.finishRegistration") }}
        </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data: () => ({
    isLoading: false,
    dateFormat: null,
    transformedUserAttributes: [],
    userAttributesMeta: {},
  }),
  computed: {
    defaultDateFormat() {
      return process.env.VUE_APP_DATE_FORMAT;
    },
    user: {
      get() {
        return this.$store?.getters?.user;
      },
      set(val) {
        this.$store.commit("SET_USER", val);
      },
    },
    category() {
      return this.$store?.getters["signup/category"];
    },
    groupId() {
      return this.$store?.getters?.group?.id;
    },
    plugin() {
      return this.$store?.getters["signup/plugin"];
    },
    groupCurrency() {
      return this.$store?.getters?.group?.currency;
    },
    defaultTaxRate() {
      return this.$store?.getters?.group?.default_tax_rate;
    },
    platformFee() {
      return this.$store?.getters?.group?.platform_fee;
    },
    totalAmount() {
      const slug = `reserved_g${this.category?.id}_payment_total`;

      return this.userAttributesMeta[slug] || 0;
    },
    taxtAmount() {
      return this.totalAmount * (this.defaultTaxRate / 100);
    },
    totalAmountExcludingTax() {
      return this.totalAmount - this.taxtAmount;
    },
    appLanguage() {
      return this.$store?.getters?.appLanguage;
    },
    attributeTags() {
      return this.$store?.getters["signup/attributeTags"];
    },
    attributeTagsEnabled() {
      return this.plugin.attributes?.signup_guests_attribute_tags;
    },
    groupedQuestions() {
      return this.attributeTags.map((tag) => ({
        ...tag,
        attributes: this.transformedUserAttributes.filter((attr) =>
          attr.tags.some((el) => el.id === tag.id),
        ),
      }));
    },
    selectedQuestion: {
      get() {
        return this.$store?.getters["signup/selectedQuestion"];
      },
      set(val) {
        this.$store.commit("signup/SET_SELECTED_QUESTION", val);
      },
    },
    selectedQuestionGroup: {
      get() {
        return this.$store?.getters["signup/selectedQuestionGroup"];
      },
      set(val) {
        this.$store.commit("signup/SET_SELECTED_QUESTION_GROUP", val);
      },
    },
    window: {
      get() {
        return this.$store?.getters["signup/window"];
      },
      set(val) {
        this.$store?.commit("signup/SET_WINDOW", val);
      },
    },
    dateFormattedDate() {
      const format = this.dateFormat || this.defaultDateFormat;

      return (date) => moment(date).format(format);
    },
  },
  watch: {
    window: {
      immediate: true,
      handler(val) {
        if (val !== 4) return;

        this.listUserAttributes();
        this.getDateFormat();
      },
    },
    appLanguage: {
      handler() {
        this.listUserAttributes();
      },
    },
  },
  methods: {
    editUser() {
      this.window = 1;
    },
    async getDateFormat() {
      const response = await this.$http.get(
        `/settings/public/global.date_format`,
      );

      this.dateFormat = response?.data?.data?.value;
    },
    async listUserAttributes() {
      try {
        this.transformedUserAttributes = [];

        const params = {
          category_id: this.category.id,
          lang: this.appLanguage,
          per_page: 10000,
        };

        this.isLoading = true;

        const response = await this.$http.get(
          `/groups/${this.groupId}/users/attributes/list_related`,
          { params },
        );

        this.isLoading = false;

        if (!response?.data?.data) {
          return null;
        }

        const userAttributes = response.data?.data;
        this.userAttributesMeta = response.data?.meta || [];

        this.transformedUserAttributes = this.transformAttributes(
          userAttributes,
          this.userAttributesMeta,
        );
      } catch (error) {
        if (error) {
          this.isLoading = false;
        }
      }
    },
    transformAttributes(attributes, attributeMetaValues) {
      const transformedAttributes = [];

      for (const attribute of attributes) {
        const value = attributeMetaValues[attribute?.slug];

        attribute.meta_value = value;

        if (["varchar", "datetime", "text"].includes(attribute.type)) {
          attribute.answer = value || "";
        }

        if (attribute.type === "options") {
          if (!attribute?.is_collection) {
            attribute.answer = attribute.options.filter(
              (option) => value === option.id,
            );
          } else {
            attribute.answer = attribute.options.filter((option) =>
              value.includes(option.id),
            );
          }
        }

        transformedAttributes.push(attribute);
      }

      return transformedAttributes;
    },
    transformAttributeForEdit(attribute) {
      return {
        ...attribute,
        details: attribute.details ? JSON.parse(attribute.details) : {},
      };
    },
    changeAnswer(attribute) {
      this.selectedQuestion = this.transformAttributeForEdit(attribute);
      this.user.attributes[attribute.slug] = attribute.meta_value;
      this.window = 3;
    },
    changeAnswers(tag) {
      this.selectedQuestionGroup = tag;
      this.window = 3;
    },
    async completeRegistration() {
      const params = {
        category_id: this.category.id,
      };

      await this.$http.post(
        `groups/${this.groupId}/users/${this.user.id}/complete_attribute_category`,
        params,
      );
    },
    async next() {
      if (this.totalAmount > 0) {
        this.window = 5;
        return null;
      }

      try {
        this.isLoading = true;

        await this.completeRegistration();

        this.$store.commit("SET_SIGNUP_QUERY", null);

        this.isLoading = false;

        this.window = 6;
      } catch (error) {
        if (error) {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
