<template>
  <div class="customized-option-select">
    <div
      v-for="(item, i) in items"
      :key="`item-${i}`"
      class="customized-option"
    >
      <div class="item-left" @click="selectItem(item.id)">
        <font-awesome-icon
          v-if="!isSelected(item.id)"
          :icon="optionIcon"
          :style="{ color: gmPrimaryColor }"
          class="icon"
        />
        <font-awesome-icon
          v-if="isSelected(item.id)"
          :icon="optionIconSelected"
          :style="{ color: gmPrimaryColor }"
          class="icon"
        />
      </div>
      <div class="item-right sw-rounded">
        <div class="item-top">
          <div class="item-name">{{ item.name }}</div>
          <div v-if="item.price" class="item-price">
            {{ formatPrice(item.price, groupCurrency) }}
          </div>
        </div>
        <div class="item-body" v-html="item.body"></div>
      </div>
    </div>
    <div class="error-message">
      <transition name="fade" mode="out-in">
        <div v-if="errorMessages">{{ errorMessages }}</div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, Array],
    },
    items: {
      type: Array,
      default: () => [],
    },
    multi: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: String,
      default: "",
    },
  },
  computed: {
    groupCurrency() {
      return this.$store?.getters?.group?.currency;
    },
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    optionIcon() {
      return ["far", this.multi ? "square" : "circle"];
    },
    optionIconSelected() {
      return ["fas", this.multi ? "check-square" : "check-circle"];
    },
  },
  methods: {
    selectItem(itemId) {
      this.$emit("change");

      if (!this.multi) {
        this.model = this.model !== itemId ? itemId : null;
        return;
      }

      if (!this.model) {
        this.model = [itemId];
        return;
      }

      if (!this.model.includes(itemId)) {
        this.model.push(itemId);
      } else {
        this.model = this.model.filter((el) => el !== itemId);
      }
    },
    isSelected(itemId) {
      return this.multi ? this.model?.includes(itemId) : this.model === itemId;
    },
  },
};
</script>

<style lang="scss" scoped>
.customized-option-select {
  .error-message {
    min-height: 14px;
    padding: 0 12px;
    font-size: 12px;
    line-height: 12px;
    color: #ff5252 !important;
  }

  .customized-option {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    &:not(:last-of-type) {
      margin-bottom: 5px;
    }

    .item-left {
      display: flex;
      align-items: center;
      padding: 0 20px 0 0;

      .icon {
        cursor: pointer;
      }
    }

    .item-right {
      width: 100%;
      padding: 15px 20px 20px;
      background-color: #f5f5f5;

      .item-top {
        display: flex;
        flex-wrap: nowrap;

        .item-name {
          width: 100%;
          font-weight: 700;
          font-size: 20px;
        }

        .item-price {
          padding: 0 0 0 20px;
          white-space: nowrap;
          text-align: right;
          font-weight: 700;
          font-size: 20px;
        }
      }
    }
  }
}
</style>
